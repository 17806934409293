.current {color: green;}

    body { background-color: #101933; color: #f0fffd; font-family: Arial, Helvetica, sans-serif; margin: 0px }
    h1   { font-size: 3.5em; color: #ff7b00; }
    h2   { font-size: 1.5em; color: #ff7b00; }
    h3   { font-size: 1.2em; color: #decb78; }
    h4   { font-size: 1.0em; color: #c7af8f; }
    h5   { font-size: 1.0em; color: #2345a3; }
    h6   { font-size: 1.05em; color: #4555a3; }

    strong { font-weight: bold; }
    b { font-weight: bold; }
    blah { font-weight: bold; }
    .third-size-image { width: 25%; }
    .half-size-image { width: 50%; }
    .red-text { color: red; }
    .column { float: left; width: 50%; max-width: 50%; }
    .columnb { float: left; max-width: 50%; }
    .columnc { float: left; width: 100%; max-width: 100%; }
    .row { content: ""; display: table; clear: both; width: 100%;}
    .blue-bg1 {    background-color: #1E90FF;}
    img { max-width: 100%; height: auto; }
    .gallery-image { box-shadow: 5px 5px 15px black; margin: 15px;}
    .gallery-imageb { margin: 15px;}
    .leftimg { float: left; }
    .rightimg { float: right; }
    .mygoodimg { border-radius: 20px; box-shadow: 5px 5px 15px black; margin: 15px; }

    .myheader { background-color: #222323; width: 100%; }
    .mynav { background-color: #0d4093; width: 100%; box-shadow: 5px 5px 15px black; }
    * { box-sizing: border-box; }

    .left {
      background-color: #0d4093;
      float: left;
      width: 20%;
      border-radius: 3px;
      box-shadow: 5px 5px 15px black;
    }

    .main {
      background-color: #222323;
      float: right;
      width: 78%;
      border-radius: 20px;
      margin-top: 10px;
      padding: 10px;
    }

    .combinedstuff { margin: 10px; margin-left: 10px; margin-right: 10px; }
    .no-dot { list-style-type:none; margin: 0; padding: 0; }

    .mybutton {
      margin-top: 12px;
      margin: 12px;
      margin-bottom: 12px;
      background-color: #023484;
    }
    .mybutton a {
      color: white;
      text-decoration: none;
      font-size: 17px;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: bold;
      width: 100%; height: 100%;
      display: inline-block;
    }
    .mybutton:hover { background-color: #356dff; }
    .mybuttonround { border-radius: 10px; background-color: #0d4093; }
    .mybuttonround:hover { border-radius: 10px; background-color: #356dff; }
    .mybuttonactive { background-color: #356dff; }
    .mybuttonroundactive { border-radius: 10px; background-color: #356dff; }
    .mybuttontop { display: inline-block;  border-radius: 10px; }

    @media screen and (max-width:800px) { .left, .main { width: 100%; margin-right: 0px; margin-left: 0px; }}

    table {  border-collapse: collapse; background-color: #548ce5; color: black; }
    table, td, th {border: 1px solid black; text-align: left;}
    tr:nth-child(even) {background-color: #859cc1;}

    .box1 {
      margin: 5px;
      /*border: 1px solid #ccc;*/
      /*float: left;*/
      background-color: #0d4093;
      width: 500px;
      border-radius: 7px; box-shadow: 5px 5px 15px black;padding: 7px;
    }
    .box1 a { text-decoration: none; color: #8fa3c4; }
    .box1 img { border-radius: 4px; }
    .box1-link {  text-decoration: none; color: #8fa3c4; }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #6d1fc1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      border-radius: 7px; box-shadow: 5px 5px 15px black;padding: 7px;
    }

    .dropdown-content a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    .body-nav {
      color: black;
      padding: 12px 16px;
      margin: 5px;
      text-decoration: none;
      color: white;
      font-size: 17px;
      font-weight: bold;
      text-align: center;
      display: block;
      background-color: #0d4093;
      border-radius: 7px; box-shadow: 5px 5px 15px black;padding: 7px;
    }
    .body-nav:hover { background-color: #356dff; }
    .dropdown-content a:hover { background-color: #1ac411; border-radius: 7px; }
    .show {display:block;}
